import { useState, useEffect, useContext, useCallback } from "react";
import {
  Row,
  Card,
  Form,
  Icon,
  Input,
  Avatar,
  Button,
  Column,
  Loader,
  Select,
  Upload,
  Switch,
  Divider,
  Breadcrumb,
  Notification,
} from "@cea/ui-kit";
import cx from "classnames";
import { camelCase, debounce } from "lodash-es";
import { useNavigate, useParams } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";

import {
  getFileFromAzure,
  getFilePath,
  getNotification,
  imageUploader,
  validateCeaEmail,
} from "../../utils";
import Appshell from "../../components/Appshell";
import { GET_CEA_MEMBERS, CREATE_USER } from "../../queries/user";
import OrganizationContext from "../../contexts/OrganizationContext";
import { CREATE_SERVICE, UPDATE_SERVICE } from "../../queries/services";
import AuthContext from "../../contexts/AuthContext";

import styles from "./create-service.module.css";

const { Meta } = Card;

const CreateService = () => {
  const [ceaMembers, setCeaMembers] = useState([]);
  const [fetchingCeaMembers, setFetchingCeaMembers] = useState(false);
  const [imagePath, setImagePath] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { serviceId } = useParams();
  const apolloClient = useApolloClient();
  const { user } = useContext(AuthContext);
  const { service, fetchServiceDetails, loading } =
    useContext(OrganizationContext);

  const [createService, { loading: createLoading }] =
    useMutation(CREATE_SERVICE);
  const [updateService, { loading: updateLoading }] =
    useMutation(UPDATE_SERVICE);
  const [createCeaMember, { loading: createUserLoading }] =
    useMutation(CREATE_USER);

  const fetchCeaMembers = async (variables = {}) => {
    try {
      setFetchingCeaMembers(true);

      const { data } = await apolloClient.query({
        query: GET_CEA_MEMBERS,
        variables,
        fetchPolicy: "network-only",
      });

      const normalized = data.getCeaMembers.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setCeaMembers(normalized);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setFetchingCeaMembers(false);
    }
  };

  const onFinish = async (values) => {
    try {
      const serviceType = camelCase(values.name);

      const admins = values.admins.map((item) => {
        if (typeof item === "string") {
          return item;
        }

        return item.value;
      });

      if (serviceId) {
        await updateService({
          variables: {
            id: serviceId,
            ...values,
            admins,
            serviceType,
            logo: imagePath,
          },
          notifyOnNetworkStatusChange: true,
        });

        Notification.success({ message: "Service Updated Successfully!" });
      } else {
        await createService({
          variables: {
            ...values,
            admins,
            serviceType,
            logo: imagePath,
          },
          notifyOnNetworkStatusChange: true,
        });

        Notification.success({ message: "Service Created Successfully!" });
        navigate("/services");
      }
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    }
  };

  useEffect(() => {
    if (serviceId && !service) {
      fetchServiceDetails({ id: serviceId });
    }
  }, []);

  useEffect(() => {
    if (service) {
      getServiceLogo();
    }
  }, [service]);

  const debouncer = useCallback(
    debounce((searchValue) => fetchCeaMembers(searchValue), 750),
    []
  );

  const onSearch = (value) => {
    debouncer({ name: value });
  };

  const getDefaultServiceAdmins = () => {
    if (serviceId) {
      return service?.admins.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    } else {
      return [];
    }
  };

  const onImageUpload = async (info) => {
    const { updatedFileName, newFile } = await imageUploader(info);
    const filePath = getFilePath(updatedFileName);

    form.setFieldsValue({
      logo: [newFile],
    });

    setImagePath(filePath);
  };

  const getServiceLogo = async () => {
    if (service.logo) {
      const logoFile = await getFileFromAzure(service?.logo);
      const filePath = getFilePath(service.logo);

      setImagePath(filePath);

      form.setFieldsValue({
        logo: [logoFile],
      });
    }
  };

  const onNameChange = (event) => {
    setInputValue(event.target.value);
  };

  const addItem = async (e) => {
    e.preventDefault();

    try {
      if (validateCeaEmail(inputValue.toLowerCase())) {
        const { data } = await createCeaMember({
          variables: {
            data: {
              email: inputValue,
              name: inputValue,
              isCeaMember: true,
              organization: user.organization.id,
            },
          },
        });

        const { id } = data.createUser;

        setCeaMembers((prevState) => [
          ...prevState,
          {
            label: inputValue,
            value: id,
          },
        ]);
        setInputValue("");
      } else {
        Notification.error({ message: "Invalid Email provided!" });
      }
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    }
  };

  return (
    <Appshell>
      <div className="page-main-container">
        <div className="breadcrumb-holder">
          <Breadcrumb>
            <Breadcrumb.Item>Services</Breadcrumb.Item>
            <Breadcrumb.Item>{serviceId ? "Edit" : "Create"}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {(() => {
          if (serviceId && loading) {
            return (
              <div className="p-8">
                <Loader tip="Loading" />
              </div>
            );
          }

          return (
            <Card>
              <Meta title="Create Service" className={styles["meta-info"]} />
              <br />
              <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                requiredMark={true}
                initialValues={{
                  ...service,
                  admins: getDefaultServiceAdmins(),
                }}
              >
                <Row gutter={45}>
                  <Column span={7}>
                    <Form.Item
                      name="name"
                      label="Service Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a service name",
                        },
                      ]}
                    >
                      <Input placeholder="Service Name" />
                    </Form.Item>
                  </Column>
                  <Column span={7}>
                    <Form.Item
                      name="description"
                      label="Service Description"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="Service Name" />
                    </Form.Item>
                  </Column>
                  <Column span={10}>
                    <Row align="middle" gutter={[24, 64]}>
                      <Column>
                        <Avatar
                          style={{
                            width: "4rem",
                            height: "4rem",
                            background: "#7265e6",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontSize: "2rem",
                          }}
                          src={imagePath}
                        >
                          {form.getFieldValue("name")
                            ? form.getFieldValue("name")[0]
                            : null}
                        </Avatar>
                      </Column>
                      <Column>
                        <Form.Item
                          name="logo"
                          label="Enter Logo Here"
                          valuePropName="fileList"
                          getValueFromEvent={(event) => [event?.file]}
                        >
                          <Upload
                            name="logo"
                            beforeUpload={(_, __) => false}
                            onChange={onImageUpload}
                            listType="picture"
                            showUploadList={false}
                            multiple={false}
                          >
                            <Button className={styles["upload-button"]}>
                              <Icon
                                name="plus"
                                color="rgba(0, 0, 0, 0.5)"
                                size={12}
                                className="mr-1"
                              />
                              Upload
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Column>
                    </Row>
                  </Column>
                </Row>
                <Row>
                  <Column span={6}>
                    <Form.Item
                      name="hasSharedDirectory"
                      label="Has Shared Directory"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Column>
                </Row>
                <Row gutter={45}>
                  <Column span={16}>
                    <Form.Item
                      name="admins"
                      label="Service Admins"
                      rules={[
                        {
                          required: true,
                          message: "Atleast one service admin is required",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Add Service Admins"
                        showSearch
                        onSearch={onSearch}
                        filterOption={false}
                        loading={fetchingCeaMembers}
                        options={ceaMembers}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider className="my-2 mx-0" />
                            <div
                              className={cx(
                                "standard-flex",
                                styles["add-item-container"]
                              )}
                            >
                              <Input
                                placeholder="Please enter item"
                                value={inputValue}
                                onChange={onNameChange}
                              />
                              <Button
                                type="text"
                                icon={
                                  <Icon
                                    name="plus"
                                    size={14}
                                    color="rgba(24, 144, 255, 1)"
                                  />
                                }
                                loading={createUserLoading}
                                disabled={createUserLoading}
                                onClick={addItem}
                              >
                                Add item
                              </Button>
                            </div>
                          </>
                        )}
                      />
                    </Form.Item>
                  </Column>
                </Row>
                <br />
                <Row>
                  <Column span={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={createLoading || updateLoading}
                        loading={createLoading || updateLoading}
                      >
                        <Icon
                          name="plus"
                          size={15}
                          color="white"
                          className="mr-2"
                        />
                        Save
                      </Button>
                    </Form.Item>
                  </Column>
                </Row>
              </Form>
            </Card>
          );
        })()}
      </div>
    </Appshell>
  );
};

export default CreateService;
