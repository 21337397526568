import QAServiceDetail from "../views/QAService/QAServiceDetail";
import SIServiceDetail from "../views/SIService/SIServiceDetail";
import PVSMIPServiceDetail from "../views/PVSMIPService/PVSMIPServiceDetail";
import ESSSMIPServiceDetail from "../views/ESSSMIPService/ESSSMIPServiceDetail";
import PVPFTServiceDetail from "../views/PVPFTService/PVPFTServiceDetail";
import EBRServiceDetail from "../views/EBRService/EBRServiceDetail";
import SCMServiceDetail from "../views/SCMService/SCMServiceDetail/SCMServiceDetail";
import CRMIServiceDetail from "../views/CRMIService/CRMIServiceDetail/CRMIServiceDetail";
import ESSPFRServiceDetail from "../views/ESSPFRService/ESSPFRServiceDetail";
import ESSSTPRServiceDetail from "../views/ESSSTPRService/ESSSTPRServiceDetail";
import GH2SMIPServiceDetail from "../views/GH2SMIPService/GH2SMIPServiceDetail";
import GH2STPRServiceDetail from "../views/GH2STPRService/GH2STPRServiceDetail";
import PVSTPRServiceDetail from "../views/PVSTPRService/PVSTPRServiceDetail";

const servicesMap = {
  qa: (props) => <QAServiceDetail {...props} />,
  emp: "",
  si: (props) => <SIServiceDetail {...props} />,
  pvSmip: (props) => <PVSMIPServiceDetail {...props} />,
  essSmip: (props) => <ESSSMIPServiceDetail {...props} />,
  pvPft: (props) => <PVPFTServiceDetail {...props} />,
  ebr: (props) => <EBRServiceDetail {...props} />,
  scm: (props) => <SCMServiceDetail {...props} />,
  customReportsMi: (props) => <CRMIServiceDetail {...props} />,
  essPfr: (props) => <ESSPFRServiceDetail {...props} />,
  essStpr: (props) => <ESSSTPRServiceDetail {...props} />,
  gh2Smip: (props) => <GH2SMIPServiceDetail {...props} />,
  gh2Stpr: (props) => <GH2STPRServiceDetail {...props} />,
  pvStpr: (props) => <PVSTPRServiceDetail {...props} />,
};

export default servicesMap;
