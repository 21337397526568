import { gql } from "@apollo/client";

export const FETCH_SERVICES = gql`
  query Services(
    $limit: Int
    $offset: Int
    $orderBy: ServicesOrderByInput
    $where: ServicesWhereInput
  ) {
    services(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
      services {
        id
        name
        description
        logo
        preview
        categories
        serviceType
        hasSharedDirectory
        admins {
          id
          name
          avatar
          email
        }
      }

      total
    }
  }
`;

export const FETCH_SERVICE_DETAILS = gql`
  query ServiceDetails($id: ID, $serviceType: String) {
    serviceDetails(data: { id: $id, serviceType: $serviceType }) {
      id
      name
      description
      logo
      preview
      serviceType
      categories
      hasSharedDirectory
      admins {
        id
        name
        avatar
        email
      }
    }
  }
`;

export const CREATE_SERVICE = gql`
  mutation CreateService(
    $name: String!
    $serviceType: String!
    $admins: [ID!]!
    $description: String
    $logo: String
    $hasSharedDirectory: Boolean
    $categories: [String!]
  ) {
    createService(
      data: {
        name: $name
        serviceType: $serviceType
        admins: $admins
        description: $description
        logo: $logo
        hasSharedDirectory: $hasSharedDirectory
        categories: $categories
      }
    ) {
      message
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation UpdateService(
    $id: ID!
    $name: String
    $serviceType: String!
    $admins: [ID!]
    $description: String
    $logo: String
    $hasSharedDirectory: Boolean
    $categories: [String!]
  ) {
    updateService(
      data: {
        id: $id
        name: $name
        serviceType: $serviceType
        admins: $admins
        description: $description
        logo: $logo
        hasSharedDirectory: $hasSharedDirectory
        categories: $categories
      }
    ) {
      message
    }
  }
`;
