import { gql } from "@apollo/client";

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $email: String!
    $newPassword: String!
    $token: String!
  ) {
    resetPassword(
      data: { email: $email, newPassword: $newPassword, token: $token }
    ) {
      message
    }
  }
`;

export const FETCH_USER = gql`
  query Me {
    me {
      id
      name
      email
      organization {
        name
        id
        clientId
      }
      isSuperAdmin
      emailVerified
      isCeaMember
      roles {
        role
        service {
          name
          id
        }
        permission
      }
      avatar
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
      message
    }
  }
`;

export const FETCH_USERS = gql`
  query Users($contains: String) {
    users(contains: $contains) {
      id
      name
      email
    }
  }
`;

export const FETCH_EMP_DASHBOARD_DATA = gql`
  query FetchEmpDashboardData($serviceId: ID!, $organization: ID!) {
    projects(where: { organization: $organization }) {
      projects {
        id
        title
        description
        createdBy {
          id
          name
        }
        location {
          address
          coordinates
        }
      }
      total
    }

    serviceUsers(data: { service: $service, organization: $organization }) {
      total
    }
  }
`;

export const GET_CEA_MEMBERS = gql`
  query GetCeaMembers($name: String) {
    getCeaMembers(where: { name: $name }) {
      id
      name
      email
    }
  }
`;

export const GET_CLIENT_USERS = gql`
  query GetClientUsers($organization: ID, $service: ID!) {
    getClientUsers(where: { service: $service, organization: $organization }) {
      id
      name
      email
      avatar
      emailVerified
    }
  }
`;

export const ADD_CLIENT = gql`
  mutation AddClient($email: String!, $organization: ID!, $service: ID!) {
    createClient(
      data: { email: $email, organization: $organization, service: $service }
    ) {
      id
      message
    }
  }
`;

export const REMOVE_CLIENT = gql`
  mutation RemoveClient($clientId: ID!, $service: ID!) {
    removeClient(data: { clientId: $clientId, service: $service }) {
      id
      message
    }
  }
`;

export const RESEND_EMAIL_VERIFICATION = gql`
  mutation ResendConfirmationEmail($email: String!, $service: ID!) {
    resendConfirmationEmail(data: { email: $email, service: $service }) {
      id
      message
    }
  }
`;

export const O_AUTH_LOGIN = gql`
  mutation OAuthLogin($name: String!, $email: String!, $accessToken: ID) {
    oAuthLogin(
      data: { name: $name, email: $email, accessToken: $accessToken }
    ) {
      token
      user {
        id
        name
        email
        organization {
          name
          id
          clientId
        }
        isSuperAdmin
        emailVerified
        isCeaMember
        roles {
          role
          service {
            name
            id
          }
          permission
        }
        avatar
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($data: CreateUserInput!) {
    createUser(data: $data) {
      id
      message
    }
  }
`;
