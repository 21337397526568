import { useState, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  Form,
  List,
  Input,
  Modal,
  Avatar,
  Button,
  Divider,
  Notification,
  Row,
  Column,
  Icon,
  Select,
} from "@cea/ui-kit";
import { useApolloClient, useMutation } from "@apollo/client";
import { debounce, pickBy, trim } from "lodash-es";

import {
  GET_CLIENT_USERS,
  ADD_CLIENT,
  REMOVE_CLIENT,
  RESEND_EMAIL_VERIFICATION,
} from "../../queries/user";
import { colorsMap, getNotification } from "../../utils";
import { GET_ORGANIZATIONS } from "../../queries/organizations";
import OrganizationContext from "../../contexts/OrganizationContext";

import styles from "./client-invite-modal.module.css";

const ClientInviteModal = ({ isVisible, handleCancel }) => {
  const [subscriptionClients, setSubscriptionClients] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [resendEmail, setResendEmail] = useState("");
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const apolloClient = useApolloClient();
  const { service, organization } = useContext(OrganizationContext);

  const [addClient] = useMutation(ADD_CLIENT);
  const [removeClient] = useMutation(REMOVE_CLIENT);
  const [resendConfirmationEmail, resendLoading] = useMutation(
    RESEND_EMAIL_VERIFICATION
  );

  const fetchOrganizations = async (variables = {}) => {
    try {
      setLoading(true);

      const { data } = await apolloClient.query({
        query: GET_ORGANIZATIONS,
        variables: {
          where: {
            ...variables,
          },
        },
      });

      const selectOptions = data.organizations.organizations.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setOrgs(selectOptions);
    } catch (error) {
      Notification.error({
        message: "Error fetching data. Please try again later!",
      });
    } finally {
      setLoading(false);
    }
  };

  const debouncer = useCallback(
    debounce((searchValue) => fetchOrganizations(searchValue), 750),
    []
  );

  const onSearch = (value) => {
    debouncer({ name: value });
  };

  const fetchServiceClients = async () => {
    const queryFilters = {
      organization: organization?.id,
      service: service.id,
    };

    try {
      const { data } = await apolloClient.query({
        query: GET_CLIENT_USERS,
        variables: pickBy(queryFilters),
        fetchPolicy: "network-only",
      });

      setSubscriptionClients(data.getClientUsers);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    }
  };

  useEffect(() => {
    if (isVisible) {
      fetchServiceClients();
    } else {
      form.resetFields();
    }
  }, [isVisible]);

  const onRemove = async (clientId) => {
    try {
      setRemoveLoading(true);

      const { data } = await removeClient({
        variables: {
          clientId,
          service: service.id,
        },
      });

      if (data.removeClient?.id) {
        setSubscriptionClients((prevState) =>
          prevState.filter((item) => item.id !== clientId)
        );
      }

      setRemoveLoading(false);
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });

      setRemoveLoading(false);
    }
  };

  const onResendEmailVerification = async (email) => {
    try {
      setResendEmail(email);

      const { data } = await resendConfirmationEmail({
        variables: {
          email,
          service: service.id,
        },
      });

      if (data.resendConfirmationEmail) {
        setSubscriptionClients((prevState) => [
          ...prevState.map((user) => {
            if (user.email === email) {
              return {
                ...user,
                resendEmail: "Email sent",
              };
            }

            return user;
          }),
        ]);
      }

      setResendEmail("");
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });

      setResendEmail("");
    }
  };

  const onFormSubmit = async (values) => {
    try {
      setAddLoading(true);

      const email = trim(values.email.toLowerCase());
      const organizationId = service.hasSharedDirectory
        ? values.organization
        : organization.id;

      const formValues = {
        email,
        service: service.id,
        organization: organizationId,
      };

      const { data } = await addClient({
        variables: pickBy(formValues),
      });

      setSubscriptionClients((prevState) => [
        ...prevState,
        {
          id: data.createClient.id,
          email,
          name: email,
          service: service.id,
          organization: organizationId,
        },
      ]);

      Notification.success({ message: "Client Invite Email has been sent!" });

      form.resetFields();
    } catch (error) {
      const message = getNotification(error);

      Notification.error({
        message,
      });
    } finally {
      setAddLoading(false);
    }
  };

  return (
    <Modal
      title="Clients"
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ minWidth: "700px", maxHeight: "500px" }}
    >
      <List
        itemLayout="horizontal"
        dataSource={subscriptionClients}
        renderItem={(item) => {
          const { name, id, email, emailVerified } = item;

          const initials = name.split(" ").map((item) => item[0]);

          return (
            <List.Item
              actions={[
                !emailVerified ? (
                  <Button
                    key="send-invite"
                    size="small"
                    type="link"
                    onClick={() => onResendEmailVerification(email)}
                    disabled={!!item?.resendEmail}
                    loading={resendEmail === email && resendLoading}
                    icon={
                      <Icon
                        name={
                          item.resendEmail ? "check_circle" : "issues_checked"
                        }
                        size={10}
                        color="var(--primary-theme)"
                      />
                    }
                  >
                    <span className="ml-1 text-xs">
                      {item?.resendEmail
                        ? item.resendEmail
                        : "Resend verification"}
                    </span>
                  </Button>
                ) : null,
                <div key="send-invite" className="flex items-center gap-1">
                  <Icon name="delete" size={12} color="var(--primary-red)" />
                  <div
                    key={id}
                    className={classnames(styles["list-remove-action"], {
                      [styles["remove-loading"]]: removeLoading,
                    })}
                    onClick={() => {
                      if (!removeLoading) {
                        onRemove(id);
                      }
                    }}
                  >
                    Remove
                  </div>
                </div>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      backgroundColor: colorsMap[initials[0].toLowerCase()],
                    }}
                  >
                    {initials}
                  </Avatar>
                }
                title={
                  <div className="flex items-center">
                    {name}{" "}
                    <div
                      className={classnames(styles["verification-badge"], {
                        [styles.verified]: emailVerified,
                        [styles["not-verified"]]: !emailVerified,
                      })}
                    >
                      <Icon
                        name={emailVerified ? "check_filled" : "issues_checked"}
                        size={12}
                        color={
                          emailVerified
                            ? "var(--primary-green)"
                            : "var(--primary-orange"
                        }
                      />
                      {emailVerified ? "Verified" : "Pending verification"}
                    </div>
                  </div>
                }
                description={<div>{email}</div>}
              />
            </List.Item>
          );
        }}
      />
      <Divider />
      <div>
        <Form onFinish={onFormSubmit} form={form}>
          <Row justify="space-between" gutter={16}>
            <Column span={18}>
              <Row gutter={16}>
                <Column span={12}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid email!!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Client Email" />
                  </Form.Item>
                </Column>
                {service?.hasSharedDirectory && (
                  <Column span={12}>
                    <Form.Item
                      name="organization"
                      rules={[
                        {
                          required: true,
                          message: "Please select an organization!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Organization"
                        showSearch
                        onSearch={onSearch}
                        filterOption={false}
                        loading={loading}
                        options={orgs}
                      />
                    </Form.Item>
                  </Column>
                )}
              </Row>
            </Column>

            <Column span={6}>
              <Form.Item className={styles["align-end"]}>
                <Button
                  htmlType="submit"
                  type="primary"
                  className={classnames(styles["list-add-action"])}
                  loading={addLoading}
                  disabled={addLoading}
                  icon={<Icon name="plus" size={14} color="#fff" />}
                >
                  Invite Client
                </Button>
              </Form.Item>
            </Column>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

ClientInviteModal.defaultProps = {
  isVisible: false,
  handleOk: () => {},
  handleCancel: () => {},
};

ClientInviteModal.propTypes = {
  isVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default ClientInviteModal;
